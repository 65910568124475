import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import GlobalContext from './context/GlobalContext';
import ScrollTop from './components/ScrollTop';

import Home from './pages/Home';
import Experience from './pages/Experience';
import Contact from './pages/Contact';
import Projects from './pages/Projects';

function App() {

  const [language, setLanguage] = useState('en');
  const [darkMode, setDarkMode] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [generateBalls, setBalls] = useState(true);

  const isMobile = window.innerWidth < 992;

  useEffect(() => {

      if(generateBalls){

        if(isMobile){
          var canvas = {
              element: document.getElementById('canvas'),
              width: document.getElementById('mobileSibebar').offsetWidth,
              height: document.getElementById('mobileSibebar').offsetHeight,
              initialize: function () {
                  this.element.style.width = this.width + 'px';
                  this.element.style.height = this.height + 'px';
                  document.body.appendChild(this.element);
              }
          };
        }else{
          var canvas = {
            element: document.getElementById('canvas'),
            width: document.getElementById('sidebar').offsetWidth,
            height: document.getElementById('sidebar').offsetHeight,
            initialize: function () {
                this.element.style.width = this.width + 'px';
                this.element.style.height = this.height + 'px';
                document.body.appendChild(this.element);
            }
          };
        }

          var Ball = {
              create: function (color, dx, dy, size) {
                  var newBall = Object.create(this);
                  newBall.dx = dx;
                  newBall.dy = dy;
                  newBall.width = size;
                  newBall.height = size;
                  newBall.element = document.createElement('div');
                  newBall.element.style.backgroundColor = color;
                  newBall.element.style.width = newBall.width + 'px';
                  newBall.element.style.height = newBall.height + 'px';
                  newBall.element.className += ' ball';
                  newBall.width = parseInt(newBall.element.style.width);
                  newBall.height = parseInt(newBall.element.style.height);
                  canvas.element.appendChild(newBall.element);
                  return newBall;
              },
              moveTo: function (x, y) {
                  this.element.style.left = x + 'px';
                  this.element.style.top = y + 'px';
              },
              changeDirectionIfNecessary: function (x, y) {
                  if (x < 0 || x > canvas.width - this.width) {
                      this.dx = -this.dx;
                  }
                  if (y < 0 || y > canvas.height - this.height) {
                      this.dy = -this.dy;
                  }
              },
              draw: function (x, y) {
                  this.moveTo(x, y);
                  var ball = this;
                  setTimeout(function () {
                      ball.changeDirectionIfNecessary(x, y);
                      ball.draw(x + ball.dx, y + ball.dy);
                  }, 2000 / 60); //Velocidade
              }
          };
          
          canvas.initialize();
          var ball1 =  Ball.create("#b02e4a", 4, 3,5); //Rosa
          var ball2 =  Ball.create("#3e859b", 1, 5,8); //Azul
          var ball3 =  Ball.create("#9fc349", 2, 2,11); //Verde
          var ball4 =  Ball.create("#7163b0", 2, 2,14); //Roxo
          ball1.draw(70, 0);
          ball2.draw(80, 20);
          ball3.draw(30, 40);
          ball4.draw(10, 100);

          setBalls(false);
      }
  },[]);

  return (
    <div className="App">
      <GlobalContext.Provider value={{language, setLanguage, darkMode, setDarkMode, toggleMenu, setToggleMenu}}>
        <Router>
          <ScrollTop />
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/projects" component={Projects}></Route>
            <Route path="/projetos" component={Projects}></Route>
            <Route path="/experience" component={Experience}><Experience /></Route>
            <Route path="/experiencia" component={Experience}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/contato" component={Contact}></Route>
          </Switch>
        </Router>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
