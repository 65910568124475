import { createContext } from 'react';

export const GlobalContext = createContext(null);

// export const GlobalProvider = ({children}) => {
//     const [language, setLanguage] = useState('en');

//     const ChangeLanguage = language => {
//         setLanguage(language);
//     }

//     return(<GlobalProvider.Provider value={{
//         ChangeLanguage
//     }}>{children}</GlobalProvider.Provider>);
// }

export default GlobalContext;