import React, {useContext, useEffect} from "react";
import GlobalContext from "../context/GlobalContext";
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import HomeSlider from '../components/HomeSlider';
import CardCompany from "../components/CardCompany";
import CardProject from '../components/CardProject';
import ContactMe from '../components/ContactMe';
import { NavLink, Link } from "react-router-dom";

import projectLegispedia from "../img/project-legispedia.jpg";
import projectDigitalks from "../img/project-digitalks.jpg";
import companyAnhembiMorumbi from "../img/company-anhembimorumbi.jpg";

import SwiperCore, { Pagination, Autoplay } from 'swiper';
SwiperCore.use([Pagination, Autoplay]);

const Home = () => {
    const {language, setLanguage} = useContext(GlobalContext);
    const isMobile = window.innerWidth < 992;
    
    useEffect(() => {
        document.body.classList.remove('hide-sidebar-mobile');
    },[]); 

    return (
        <div>
            <Sidebar/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9 offset-md-3 col-index">
                        <Header />

                        <div className="content-div p-30 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="w-full block bg-pink m-0 rounded-top-small page-title"></span>
                                        <div className="bg-editor-gray white p-15 mb-30 rounded-bottom-small mobile-text-center">
                                            <div className="row">
                                                <div className="col-md-11">
                                                    {language == 'en' ?
                                                        <h1 className="pink font-comfortaa font-28 m-0">Hi! Welcome</h1>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <h1 className="pink font-comfortaa font-28 m-0">Olá! Seja Bem-vindo(a)</h1>
                                                    :''}
                                                    {language == 'en' ?
                                                        <p className="mt-10 mb-10">
                                                            Throughout my career, I have primarily worked with HTML, CSS, JavaScript, and jQuery, transforming layouts into code. 
                                                            I have focused on WordPress and Shopify platforms and participated in several projects using ReactJS, AngularJS, VueJS, Ionic, BigCommerce, and WooCommerce. 
                                                            I am now seeking new challenges focused on <strong>ReactJS</strong>.
                                                        </p>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <p className="mt-10 mb-10">
                                                            Ao longo da minha carreira, trabalhei principalmente com HTML, CSS, JavaScript e jQuery, transformando layouts em código. 
                                                            Trabalhei focada nas plataformas Wordpress e Shopify e participei de alguns projetos em ReactJS, AngularJS, VueJS, Ionic, Bigcommerce e Woocommerce.
                                                            Agora estou em busca de novos desafios focados em <strong>ReactJS</strong>.
                                                            <br />
                                                        </p>
                                                    :''}
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                    {language == 'en' ?
                                        <h2 className="font-18 dark-pink mb-0 bold mobile-text-center">Skills</h2>
                                    :''}
                                    {language == 'pt' ?
                                        <h2 className="font-18 dark-pink mb-0 bold mobile-text-center">Conhecimento</h2>
                                    :''}
                                    
                                    <div className="row g-2 mt-20 mb-20">
                                        <div className="col-md-6 pr-md-1">
                                            <ul>
                                                <li className="mb-5">
                                                    <span className="bg-red-html skill tag white align-middle mb-2">
                                                        <i className="icon icon-html bg-white"></i>
                                                        HTML
                                                    </span>
                                                </li>
                                                <li className="mb-5">
                                                    <span className="bg-blue-css skill tag white align-middle mb-2">
                                                        <i className="icon icon-css bg-white"></i>
                                                        CSS
                                                    </span>
                                                </li>
                                                <li className="mb-5">
                                                    <span className="bg-yellow-javascript skill tag white align-middle mb-2">
                                                        <i className="icon icon-javascript bg-white"></i>
                                                        JavaScript
                                                    </span>
                                                </li>
                                                <li className="mb-5">
                                                    {language == 'en' ?
                                                        <span className="bg-blue-usa skill tag white align-middle mb-2">
                                                            <i className="icon icon-usa-flag bg-white"></i>
                                                            English
                                                        </span>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <span className="bg-blue-usa skill tag white align-middle mb-2">
                                                            <i className="icon icon-usa-flag bg-white"></i>
                                                            Inglês
                                                        </span>                                                        
                                                    :''}
                                                </li>
                                                <li className="mb-5">
                                                    {language == 'en' ?
                                                        <span className="bg-green skill tag white align-middle mb-2">
                                                            <i className="icon icon-brazil-flag bg-white"></i>
                                                            Portuguese
                                                        </span>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <span className="bg-green skill tag white align-middle mb-2">
                                                        <i className="icon icon-brazil-flag bg-white"></i>
                                                        Português
                                                    </span>                                                        
                                                    :''}
                                                </li>
                                                {/*<li className="mb-5">
                                                    <span className="bg-blue-jquery skill tag white align-middle mb-2">
                                                        <i className="icon icon-jquery bg-white"></i>
                                                        JQuery
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    {language == 'en' ?
                                                        <span className="bg-purple-xd skill tag white align-middle mb-2">
                                                            <i className="icon icon-file bg-white"></i>
                                                            Layout to HTML
                                                        </span>
                                                    :''}
                                                    {language == 'pt' ?
                                                        <span className="bg-purple-xd skill tag white align-middle mb-2">
                                                            <i className="icon icon-file bg-white"></i>
                                                            Layout para HTML
                                                        </span>                                                     
                                                    :''}
                                                </li>*/}
                                                {/*}
                                                <li className="mb-5">
                                                    {language == 'en' ?
                                                    <span className="bg-turquoise skill tag white align-middle mb-2">
                                                        <i className="icon icon-responsive bg-white"></i>
                                                        Responsiveness
                                                    </span>
                                                    :''}
                                                    {language == 'pt' ?
                                                    <span className="bg-turquoise skill tag white align-middle mb-2">
                                                        <i className="icon icon-responsive bg-white"></i>
                                                        Responsividade
                                                    </span>                                                  
                                                    :''}
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-purple skill tag white align-middle mb-2">
                                                        <i className="icon icon-seo bg-white"></i>
                                                        SEO Markup
                                                    </span>
                                                </li>*/}
                                            </ul>
                                        </div>
                                        <div className="col-md-6 pl-md-1">
                                            <ul>
                                                <li className="mb-5">
                                                    <span className="bg-blue-reactjs skill tag white align-middle mb-2">
                                                        <i className="icon icon-reactjs bg-white"></i>
                                                        React
                                                    </span>
                                                </li>
                                                {/*<li className="mb-5">
                                                    <span className="bg-gray-react skill tag white align-middle mb-2">
                                                        <i className="icon icon-reactjs bg-white"></i>
                                                        React Native
                                                    </span>
                                                </li>*/}
                                                <li className="mb-5">
                                                    <span className="bg-black skill tag white align-middle mb-2">
                                                        <i className="icon icon-next bg-white"></i>
                                                        Next.js
                                                    </span>
                                                </li>
                                                <li className="mb-5">
                                                    <span className="bg-blue-typescript skill tag white align-middle mb-2">
                                                        <i className="icon icon-typescript bg-white"></i>
                                                        TypeScript
                                                    </span>
                                                </li>
                                                <li className="mb-5">
                                                    <span className="bg-red-git skill tag white align-middle mb-2">
                                                        <i className="icon icon-git bg-white"></i>
                                                        Git
                                                    </span>
                                                </li>
                                                {/*<li className="mb-5">
                                                    <span className="bg-black skill tag white align-middle mb-2">
                                                        <i className="icon icon-reactjs bg-white"></i>
                                                        Redux
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-black skill tag white align-middle mb-2">
                                                        <i className="icon icon-reactjs bg-white"></i>
                                                        APIs Restful
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-black skill tag white align-middle mb-2">
                                                        <i className="icon icon-reactjs bg-white"></i>
                                                        Testes Unitários e de Integração
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-black skill tag white align-middle mb-2">
                                                        <i className="icon icon-reactjs bg-white"></i>
                                                        Metodologias ágeis (Scrum, Kanban)
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-red-angular skill tag white align-middle mb-2">
                                                        <i className="icon icon-angular bg-white"></i>
                                                        AngularJS
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-green-vuejs skill tag white align-middle mb-2">
                                                        <i className="icon icon-vuejs bg-white"></i>
                                                        VueJS
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-blue-ionic skill tag white align-middle mb-2">
                                                        <i className="icon icon-ionic bg-white"></i>
                                                        Ionic
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-blue-wordpress skill tag white align-middle mb-2">
                                                        <i className="icon icon-wordpress bg-white"></i>
                                                        Wordpress
                                                    </span>
                                                </li>  */}
                                                {/*<li className="mb-5">
                                                    <span className="bg-green-shopify skill tag white align-middle mb-2">
                                                        <i className="icon icon-shopify bg-white"></i>
                                                        Shopify
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-black skill tag white align-middle mb-2">
                                                        <i className="icon icon-bigcommerce bg-white"></i>
                                                        BigCommerce
                                                    </span>
                                                </li>*/}
                                                {/*<li className="mb-5">
                                                    <span className="bg-purple-woocommerce skill tag white align-middle mb-2">
                                                        <i className="icon icon-woocommerce"></i>
                                                        WooCommerce
                                                    </span>
                                                </li>*/}
                                            </ul>
                                        </div>
                                    </div>

                                    {language == 'en' ?
                                        <Link to="/experience">
                                            <a className="shrink-border mb-30 mt-10 w-full text-center">Work experience</a>
                                        </Link>
                                    :''}
                                    {language == 'pt' ?
                                        <Link to="/experience">
                                            <a className="shrink-border mb-30 mt-10 w-full text-center">Experiência de trabalho</a>
                                        </Link>
                                    :''}

                                    {/*
                                    {language == 'en' ?
                                        <h2 className="font-18 dark-pink mb-30 bold mobile-text-center">Graduation</h2>
                                    :''}
                                    {language == 'pt' ?
                                        <h2 className="font-18 dark-pink mb-30 bold mobile-text-center">Formação</h2>
                                    :''}

                                    {language == 'en' ?
                                        <CardCompany 
                                            type="Technology degree"
                                            university={true}
                                            image={companyAnhembiMorumbi}
                                            name="Analysis and Systems Development"
                                            position="Anhembi Morumbi University"
                                            period="2015 - 2017"
                                        />
                                    :''}
                                    {language == 'pt' ?
                                        <CardCompany 
                                            type="Tecnólogo"
                                            university={true}
                                            image={companyAnhembiMorumbi}
                                            name="Análise e Desenvolvimento de Sistemas"
                                            position="Universidade Anhembi Morumbi"
                                            period="2015 - 2017"
                                        />
                                    :''}
                                    */}

                                </div>
                                <div className="col-md-8">
                                    {language == 'en' ?
                                        <h2 className="font-18 dark-pink mb-20 bold mobile-text-center">Featured Projects</h2>
                                    :''}
                                    {language == 'pt' ?
                                        <h2 className="font-18 dark-pink mb-20 bold mobile-text-center">Projetos em Destaque</h2>
                                    :''}

                                    {/*<HomeSlider />*/}


                                    <div className="row mb-20">
                                        <div className="col-md-6 mb-20">
                                            {language == 'en' ?
                                            <CardProject
                                                image={projectLegispedia}
                                                title="Legispedia"
                                                type="Web App"
                                                tecnology="reactjs"
                                                developedReason="Developed at Work"
                                                description="Legispedia is a tool to study and understand Law."
                                                link="http://andressabertolini.com/project/legispedia"
                                                className="h-full"
                                            />
                                            :''}
                                            {language == 'pt' ?
                                            <CardProject
                                                image={projectLegispedia}
                                                title="Legispedia"
                                                type="Aplicativo Web"
                                                tecnology="reactjs"
                                                developedReason="Devenvolvido para Empresa"
                                                description="A Legispedia é uma ferramenta estudar e entender o Direito."
                                                link="http://andressabertolini.com/project/legispedia"
                                                className="h-full"
                                            />
                                            :''}
                                        </div>
                                        <div className="col-md-6 mb-20">
                                            {language == 'en' ?
                                            <CardProject
                                                image={projectDigitalks}
                                                title="Digitalks"
                                                type="Interactive Menu"
                                                tecnology="html"
                                                developedReason="Developed at Work"
                                                description="Scroll navigation menu lobby to access Digitalks course content."
                                                link="http://andressabertolini.com/project/digitalks"
                                                className="h-full"
                                            />
                                            :''}
                                            {language == 'pt' ?
                                            <CardProject
                                                image={projectDigitalks}
                                                title="Digitalks"
                                                type="Menu Interativo"
                                                tecnology="html"
                                                developedReason="Devenvolvido para Empresa"
                                                description="Menu de navegação scroll em lobby para acessar conteúdo do curso Digitalks."
                                                link="http://andressabertolini.com/project/digitalks"
                                                className="h-full"
                                            />
                                            :''}
                                        </div>
                                    </div>

                                    {/*language == 'en' ?
                                        <Link to="/experience">
                                            <a className="shrink-border mb-30 mt-10 w-300 text-center">All projects</a>
                                        </Link>
                                    :''}
                                    {language == 'pt' ?
                                        <Link to="/experience">
                                            <a className="shrink-border mb-30 mt-10 w-300 text-center">Todos os projetos</a>
                                        </Link>
                                    :''*/}

                                    {/*
                                    {language == 'en' ?
                                        <Link to="/projects">
                                            <a className="shrink-border mb-10 mt-10 w-full text-center">All the projects</a>
                                        </Link>
                                    :''}
                                    {language == 'pt' ?
                                        <Link to="/projects">
                                            <a to="/projects" className="shrink-border mb-10 mt-10 w-full text-center">Todos os projetos</a>
                                        </Link>
                                    :''}
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactMe />
        </div>
    );

}

export default Home;