import React from "react";

class PageTitle extends React.Component{
    render(){
        return(
            <div>
                <span className="w-full block bg-pink m-0 rounded-top-small page-title"></span>
                <div className="bg-editor-gray white p-15 mb-30 rounded-bottom-small">
                    <div className="row">
                        <div className="col-md-11">
                            <h1 className="font-comfortaa pink mb-10 font-28">{this.props.title}</h1>
                            <p className="font-16">{this.props.description} <br /><span className="pink"><strong>{this.props.note}</strong></span></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageTitle;