import React from "react";
class CardCouse extends React.Component{
    render(){
        return(
            <div className="bg-white border-gray shadow dark-gray p-15 mb-10 rounded-small dark-mode-bg dark-mode-white">
                <div className="bg-light-gray mr-15 bg-cover bg-center w-50 h-50 rounded-full mx-auto mb-10 relative z-1 border-5-gray float-left" 
                style={{backgroundImage: `url(${this.props.image})`}}></div>

                <div className="ml-60">
                    <p className="text-uppercase font-10">{this.props.year}</p>
                    <h3 className="font-16 bold m-0">{this.props.title}</h3>
                    <p className="font-14">{this.props.school}</p>
                    <p className="text-uppercase font-10">{this.props.duration}</p>
                </div>
            </div>
        )
    }
}

export default CardCouse;