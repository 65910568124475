import React, {useContext, useState, useEffect} from "react";
import GlobalContext from "../context/GlobalContext";
import Sidebar from "../components/Sidebar";
import Header from '../components/Header';
import PageTitle from "../components/PageTitle";
import CardProject from "../components/CardProject";
import ContactMe from '../components/ContactMe';

import projectuTalk from "../img/project-utalk.jpg";
import projectDrCarrot from "../img/project-drcarrot.jpg";
import projectXmasCard from "../img/project-xmas.jpg";
import projectDailyPhoto from "../img/project-dailyphoto.jpg";
import projectSweatifyMobile from "../img/project-sweatify-mobile.jpg";
import projectIdealEnergia from "../img/project-ideal-energia.jpg";
import projectVBlock from "../img/project-v-block.jpg";
import projectMakkon from "../img/project-makkon.jpg";
import projectFitasProgresso from "../img/project-fitas-progresso.jpg";
import projectGPSLifetime from "../img/project-gps.jpg";
import projectWordpressJaFuiMandioca from "../img/project-wordpress-jafuimandioca.jpg";
import projectReactjsWeatherApp from "../img/project-reactjs-weather-app.jpg";
import projectDigitalks from "../img/project-digitalks.jpg";
import projectSprylifeMobile from "../img/project-sprylife-mobile.jpg";
import projectLegispedia from "../img/project-legispedia.jpg";
import projectOficina1 from "../img/project-oficina1.jpg";
import projectCovabraViagemDosSonhos from "../img/project-covabra-viagem-dos-sonhos.jpg";
import projectCPA from "../img/project-cpa.jpg";
import projectDrPediu from "../img/project-dr-pediu.jpg";
import projectCiclaInstitucional from "../img/project-cicla-institucional.jpg";
import projectGaleriaDoRock from "../img/project-galeria-do-rock.jpg";
import projectPapoDeGaragem from "../img/project-papo-de-garagem.jpg";
import projectSuperVarejo from "../img/project-super-varejo.jpg";
import projectMegaWhatLanding from "../img/project-megawhat-landing.jpg";
import projectHarryPotterQuebraCabeca3D from "../img/project-harry-potter-quebra-cabeca-3d.jpg";
import projectOleoPeloFuturo from "../img/project-oleo-pelo-futuro.jpg";
import projectBikeAndPark from "../img/project-bike-and-park.jpg";
import projectRicardoOppi from "../img/project-ricardo-oppi.jpg";
import projectTurmaDaMonicaOFilme from "../img/project-turma-da-monica-o-filme.jpg";
import projectVenturo from "../img/project-venturo.jpg";
import projectJuliaoInstitucional from "../img/project-juliao-institucional.jpg";
import projectMegawhatPaginaEquipe from "../img/project-megawhat-pagina-equipe.jpg";
import projectSulamericaAssistenciaInventario from "../img/project-sulamerica-assistencia-inventario.jpg";
import projectLoopaDashboard from "../img/project-loopa-dashboard.jpg";
import projectLoopaCadastro from "../img/project-loopa-cadastro.jpg";
import projectTrainmec from "../img/project-trainmec.jpg";
import projectCompasso from "../img/project-compasso.jpg";
import projectCave from "../img/project-cave.jpg";
import projectParpe from "../img/project-parpe.jpg";
import projectDoc88 from "../img/project-doc88.jpg";
import projectBradescoSeguro from "../img/project-bradesco-seguros.jpg";
import projectCarglass from "../img/project-carglass.jpg";
import projectLifeMattersApp from "../img/project-life-matters-app.jpg";
import projectMiniDreamersDesignersPage from "../img/project-minidreamers-designers-page.jpg";
import projectOrElleOurPages from "../img/project-or-elle-our-pages.jpg";

const Projects = () =>{
    const {language, setLanguage} = useContext(GlobalContext);
    const [openMenu, setMenu] = useState(false);
    const [query, setQuery] = useState("");
    
    const projects = [
        {
            image: projectLegispedia,
            titleEn: 'Legispedia',
            titlePt: 'Legispedia',
            typeEn: 'Web App',
            typePt: 'Aplicativo Web',
            tecnology: 'reactjs',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Legispedia is a tool to study and understand Law.',
            descriptionPt: 'A Legispedia é uma ferramenta estudar e entender o Direito.',
            link: 'http://andressabertolini.com/project/legispedia',
        },
        {
            image: projectLoopaDashboard,
            titleEn: 'Loopa Dashboard',
            titlePt: 'Loopa Dashboard',
            typeEn: 'Dashboard',
            typePt: 'Painel',
            tecnology: 'vuejs',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Home of Loopa\'s payment system dashboard, developed with Vue.js.',
            descriptionPt: 'Página inicial do painel do sistema de pagamentos da Loopa, desenvolvido em Vue.js.',
            link: 'http://andressabertolini.com/project/loopa-dashboard',
        },
        {
            image: projectSuperVarejo,
            titleEn: 'Super Varejo',
            titlePt: 'Super Varejo',
            typeEn: 'Magazine Portal',
            typePt: 'Portal da Revista',
            tecnology: 'vuejs',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'SuperVarejo is a digital magazine focused on publishing content about the supermarket sector.',
            descriptionPt: 'A SuperVarejo é uma revista digital com foco na publicação de conteúdo sobre o setor supermercadista.',
            link: '',
        },
        {
            image: projectIdealEnergia,
            titleEn: 'Ideal Energy',
            titlePt: 'Ideal Energia',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'angularjs',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institucional Website in Angular for a B2B alternative energy company.',
            descriptionPt: 'Site institucional em Angular para uma empresa de energia alternativa B2B.',
            link: '',
        },
        {
            image: projectCovabraViagemDosSonhos,
            titleEn: 'Covabra Viagem dos Sonhos',
            titlePt: 'Covabra Viagem dos Sonhos',
            typeEn: 'Supermarket Promotion',
            typePt: 'Promoção de Supermercado',
            tecnology: 'angularjs',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Page for the dream trip promotion held by the Covabra supermarket.',
            descriptionPt: 'Página para a promoção viagem dos sonhos realizada pelo supermercado Covabra.',
            link: '',
        },
        {
            image: projectDigitalks,
            titleEn: 'Digitalks',
            titlePt: 'Digitalks',
            typeEn: 'Interactive Menu',
            typePt: 'Menu Interativo',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Scroll navigation menu lobby to access Digitalks course content.',
            descriptionPt: 'Menu de navegação scroll em lobby para acessar conteúdo do curso Digitalks.',
            link: 'http://andressabertolini.com/project/digitalks',
        },
        {
            image: projectXmasCard,
            titleEn: 'XMas Card',
            titlePt: 'XMas Card',
            typeEn: 'Holiday card maker',
            typePt: 'Criador de cartão de natal',
            tecnology: 'html',
            developedReasonEn: 'Personal Project',
            developedReasonPt: 'Projeto Pessoal',
            descriptionEn: 'Site to create a Christmas card and share it with someone special, sending the image or printing.',
            descriptionPt: 'Site para criar um cartão de natal e compartilhar com alguém especial, enviando a imagem ou imprimindo.',
            link: 'http://andressabertolini.com/project/xmas',
        },
        {
            image: projectDailyPhoto,
            titleEn: 'Daily Photo',
            titlePt: 'Daily Photo',
            typeEn: 'Wallpaper download',
            typePt: 'Downloas de papel de parede',
            tecnology: 'html',
            developedReasonEn: 'Personal Project',
            developedReasonPt: 'Projeto Pessoal',
            descriptionEn: 'In this site you can download everyday a free image to use as your desktop wallpaper.',
            descriptionPt: 'Nesse site todo dia um papel de parede diferente está disponível para download.',
            link: 'http://victoriysuka.com/dailyphoto/',
        },
        /*{
            image: projectReactjsWeatherApp,
            titleEn: 'Weather App',
            titlePt: 'App de clima',
            typeEn: 'Web App',
            typePt: 'Web App',
            tecnology: 'reactjs',
            developedReasonEn: 'Personal Project',
            developedReasonPt: 'Projeto Pessoal',
            descriptionEn: 'This ReactJS project shows the weather in 4 cities. When you switch between them, the background changes according to the weather and if it\'s night or day.',
            descriptionPt: 'Esse app em ReactJS mostra o clima de 4 países. Quando você troca de país, a imagem de fundo muda de acordo com o clima e se é dia ou noite naquele país.',
            link: 'https://andressabertolini.github.io/weather-app/',
        },*/
        /*,
        {
            image: projectDrCarrot,
            titleEn: 'Dr. Carrot',
            titlePt: 'Dr. Carrot',
            typeEn: 'Online test',
            typePt: 'Teste online',
            tecnology: 'html',
            developedReasonEn: 'Personal Project',
            developedReasonPt: 'Projeto Pessoal',
            descriptionEn: 'Dr. Carrot is a humorous quiz where you can discovery your fruit/vegetable of lucky.',
            descriptionPt: 'Dr. Carrot é um teste bem humorado aonde você pode descobrir qual é a sua fruta/vegetal da sorte.',
            link: 'http://andressabertolini.com/project/dr-carrot',
        }*/,
        {
            image: projectTurmaDaMonicaOFilme,
            titleEn: 'Turma da Mônica The Movie',
            titlePt: 'Turma da Mônica O Filme',
            typeEn: 'Movie Website',
            typePt: 'Site do filme',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'The website of the live action movie of the classical brazilian comics "Turma da Mônica".',
            descriptionPt: 'O website de divulgação do filme live action dos clássicos dos quadrinhos Turma da Mônica.',
            link: '',
        },
        {
            image: projectHarryPotterQuebraCabeca3D,
            titleEn: 'Harry Potter 3D Puzzle',
            titlePt: 'Harry Potter Quebra-Cabeça 3D',
            typeEn: 'Product Landing Page',
            typePt: 'Landing Page do produto',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'This landing page was developed for divulge the 3d puzzle series of Harry Potter.',
            descriptionPt: 'Essa landing page foi desenvolvida para divulgar o quebra-cabeça 3D do Harry Potter, com vários cenários do filme para montar.',
            link: '',
        },
        {
            image: projectBradescoSeguro,
            titleEn: 'Bradesco Insurance',
            titlePt: 'Bradesco Seguro',
            typeEn: 'Landing Page',
            typePt: 'Landing Page',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Landing page for a bank who offers car insurance.',
            descriptionPt: 'Landing page para divulgar o seguro para carros da Bradesco.',
            link: '',
        },
        {
            image: projectSulamericaAssistenciaInventario,
            titleEn: 'Sulamerica Inventory Assistance',
            titlePt: 'Sulamerica Assistência Inventário',
            typeEn: 'Form page',
            typePt: 'Página de formulário',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'This website is an inventory assistance form with 4 pages and a lot of different types of inputs.',
            descriptionPt: 'Formulário de assistência inventário da Sulamérica, contém aplicações de vários estilos de inputs de formulário.',
            link: '',
        },  
        {
            image: projectGaleriaDoRock,
            titleEn: 'Rock Gallery',
            titlePt: 'Galeria do Rock',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Website of the iconic build, and so tourist spot in São Paulo, Rock Gallery. You can find a lot of Rock N\' Roll there.',
            descriptionPt: 'Site da famosa galeria do rock, ponto turistico de São Paulo. Você econconta vários artigos de Rock N\' Roll lá.',
            link: '',
        },
        /*{
            image: projectLoopaCadastro,
            titleEn: 'Loopa Sign Up Form',
            titlePt: 'Cadastro Loopa',
            typeEn: 'Form page',
            typePt: 'Página de formulário',
            tecnology: 'vuejs',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Registration flow in various stages of Loopa\'s payment system.',
            descriptionPt: 'Fluxo de cadastro em várias etapas do sistema de pagamentos da Loopa.',
            link: '',
        },*/
        {
            image: projectFitasProgresso,
            titleEn: 'Progresso Ribbons',
            titlePt: 'Fitas Progresso',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institutional website of the manufacturer of ribbons and bows Fitas Progresso, which also maintains the "Crafts Club" project.',
            descriptionPt: 'Site institucional do fabricante de fitas e laços Fitas Progresso, que também mantém o projeto Clube de Artesanato.',
            link: '',
        },
        {
            image: projectDoc88,
            titleEn: 'Doc88',
            titlePt: 'Doc88',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institutional website of the Doc88 technology company.',
            descriptionPt: 'Site institucional da empresa de tecnologia Doc88.',
            link: '',
        },
        {
            image: projectCave,
            titleEn: 'Cave',
            titlePt: 'Cave',
            typeEn: 'Agency Website',
            typePt: 'Site da Agência',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Website of the agency I worked for, Cave Digital, with the company\'s client portfolio.',
            descriptionPt: 'Site da agência que trabalhava, Cave Digital, com o portfólio dos clientes da empresa.',
            link: '',
        },
        {
            image: projectMegaWhatLanding,
            titleEn: 'Megawhat',
            titlePt: 'Megawhat',
            typeEn: 'Landing Page',
            typePt: 'Landing Page',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Megawhat is a platform to bring information about the energy market.',
            descriptionPt: 'Megawhat é a plataforma para trazer informação sobre o mercado de energia.',
            link: '',
        },
        {
            image: projectVBlock,
            titleEn: 'V-Block',
            titlePt: 'V-Block',
            typeEn: 'Landing Page',
            typePt: 'Landing Page',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Landing page developed using the Tailwind framework. The client is a leather producer with nanotechnology to inactivate the SARS-Cov-2 virus.',
            descriptionPt: 'Landing page desenvolvida utilizando o framework Tailwind. Cliente produtor de couro com nanotecnologia para inativar o vírus SARS-Cov-2.',
            link: '',
        },
        {
            image: projectOficina1,
            titleEn: 'Oficina 1',
            titlePt: 'Oficina 1',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Company responsible for implementing and maintaining TOTVS ERP Protheus',
            descriptionPt: 'Empresa responsável por implementação e manutenção do ERP Protheus da TOTVS.',
            link: '',
        },
        {
            image: projectCPA,
            titleEn: 'CPA Consulting',
            titlePt: 'CPA Consulting',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Business process management and automation company.',
            descriptionPt: 'Empresa de automação e gestão de processos de negócios.',
            link: '',
        },
        {
            image: projectCompasso,
            titleEn: 'Compasso',
            titlePt: 'Compasso',
            typeEn: 'Educational Platform',
            typePt: 'Plataforma educacional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Compasso\'s course platform, system with course visualization and storage of current stage progress.',
            descriptionPt: 'Plataforma de cursos da Compasso, sistema com visualização de cursos e armazenamento do progresso da etapa atual.',
            link: '',
        },
        {
            image: projectDrPediu,
            titleEn: 'Dr. Pediu',
            titlePt: 'Dr. Pediu',
            typeEn: 'Mobile App Website',
            typePt: 'Site de Aplicativo',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Prescription and medical management application website.',
            descriptionPt: 'Site de aplicativo de prescrição e gestão médica.',
            link: '',
        },
        {
            image: projectCiclaInstitucional,
            titleEn: 'Cicla',
            titlePt: 'Cicla',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institutional website of Cicla, an NGO that carries out social recycling projects.',
            descriptionPt: 'Site institucional da Cicla, uma ONG que faz projetos sociais de reciclagem.',
            link: '',
        },
        {
            image: projectPapoDeGaragem,
            titleEn: 'Papo de Garagem',
            titlePt: 'Papo de Garagem',
            typeEn: 'Landing Page',
            typePt: 'Landing Page',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Landing page for the garage chat event.',
            descriptionPt: 'Landing page para o evento papo de garagem.',
            link: '',
        },
        {
            image: projectJuliaoInstitucional,
            titleEn: 'Julião Coelho Advogados',
            titlePt: 'Julião Coelho Advogados',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institutional website for the Julião Coelho law firm.',
            descriptionPt: 'Site institucional para o escritório de advocacia Julião Coelho.',
            link: '',
        },
        /*{
            image: projectMegawhatPaginaEquipe,
            titleEn: 'Megawhat',
            titlePt: 'Megawhat',
            typeEn: 'Team Page',
            typePt: 'Página da equipe',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Creation of the team page for the Megawhat institutional website.',
            descriptionPt: 'Criação da página de equipe para o site institucional de Megawhat.',
            link: '',
        },*/
        {
            image: projectVenturo,
            titleEn: 'Venturo',
            titlePt: 'Venturo',
            typeEn: 'Institucional Wesite',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institutional website of the Venturo consultancy.',
            descriptionPt: 'Site institucional da Venturo assessoria.',
            link: '',
        },
        {
            image: projectParpe,
            titleEn: 'Parpe',
            titlePt: 'Parpe',
            typeEn: 'App Website',
            typePt: 'Página do Aplicativo',
            tecnology: 'html',
            developedReasonEn: 'Freelance',
            developedReasonPt: 'Freelance',
            descriptionEn: 'Website for car rental app parpe.',
            descriptionPt: 'Site para o aplicativo de aluguel de carros parpe.',
            link: '',
        },
        {
            image: projectCarglass,
            titleEn: 'Carglass',
            titlePt: 'Carglass',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'html',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Carglass is a company that offers various services for changing glass and car repairs.',
            descriptionPt: 'Carglass é uma empresa que oferece vários serviços para troca de vidros e reparos automotivos.',
            link: '',
        },
        {
            image: projectuTalk,
            titleEn: 'uTalk',
            titlePt: 'uTalk',
            typeEn: 'Online course',
            typePt: 'Curso Online',
            tecnology: 'html',
            developedReasonEn: 'Personal Project',
            developedReasonPt: 'Projeto Pessoal',
            descriptionEn: 'uTalk is a website that sells courses to learn English online.',
            descriptionPt: 'uTalk é um site que vende cursos de para aprender inglês online.',
            link: 'http://andressabertolini.com/project/utalk',
        },
        {
            image: projectSweatifyMobile,
            titleEn: 'Sweatify App',
            titlePt: 'Sweatify App',
            typeEn: 'Mobile App',
            typePt: 'Aplicativo mobile',
            tecnology: 'ionic',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Ionic + AngularJS mobile app for subscribers take online exercise classes.',
            descriptionPt: 'Aplicativo em Ionic + AngularJS para assinantes terem aulas de exercício físico online.',
            link: '',
        },
        {
            image: projectSprylifeMobile,
            titleEn: 'Sprylife App',
            titlePt: 'Sprylife App',
            typeEn: 'Mobile App',
            typePt: 'Aplicativo Mobile',
            tecnology: 'ionic',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Sprylife is a platform designed for students to find their personal trainer.',
            descriptionPt: 'A Sprylife é uma plataforma desenvolvida para alunos encontrarem seu personal trainers.',
            link: '',
        },
        {
            image: projectLifeMattersApp,
            titleEn: 'Life Matters',
            titlePt: 'Life Matters',
            typeEn: 'Mobile App',
            typePt: 'Aplicativo Mobile',
            tecnology: 'ionic',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Meditation application in ionic Life Matters, with courses related to the theme.',
            descriptionPt: 'Aplicativo de meditação em ionic LIfe Matters, com cursos relacionados ao tema.',
            link: '',
        },
        {
            image: projectGPSLifetime,
            titleEn: 'GPS Lifetime',
            titlePt: 'GPS Lifetime',
            typeEn: 'News portal',
            typePt: 'Portal de Notícias',
            tecnology: 'wordpress',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'GPS Lifetime Magazine News Portal in the capital of Brazil, Brasilia.',
            descriptionPt: 'Portal de notícias da revista GPS Lifetime de Brasília.',
            link: '',
        },
        {
            image: projectOleoPeloFuturo,
            titleEn: 'Óleo pelo Futuro',
            titlePt: 'Óleo pelo Futuro',
            typeEn: 'Campaign Site',
            typePt: 'Site de Campanha',
            tecnology: 'wordpress',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Oil recycling campaign website.',
            descriptionPt: 'Site para campanha de reciclagem de óleo.',
            link: '',
        },
        {
            image: projectWordpressJaFuiMandioca,
            titleEn: 'I used to be cassava',
            titlePt: 'Já fui mandioca',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'wordpress',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institucional website for a client who sells biodegradable packages.',
            descriptionPt: 'Site Institucional para cliente que produz embalagens biodegradáveis feitas de mandioca.',
            link: '',
        },
        {
            image: projectBikeAndPark,
            titleEn: 'Bike & Park',
            titlePt: 'Bike & Park',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'wordpress',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Creation of the team page for the Megawhat institutional website.',
            descriptionPt: 'Criação da página de equipe para o site institucional de Megawhat.',
            link: '',
        },
        {
            image: projectRicardoOppi,
            titleEn: 'Ricardo Oppi',
            titlePt: 'Ricardo Oppi',
            typeEn: 'Institucional Website',
            typePt: 'Site Institucional',
            tecnology: 'wordpress',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Institutional website Ricardo Oppi, workshop for restoration of old vehicles and automotive repair.',
            descriptionPt: 'Site institucional Ricardo Oppi, oﬁcina de restauração de veículos antigos e reparação automotiva.',
            link: '',
        },
        {
            image: projectMakkon,
            titleEn: 'Makkon',
            titlePt: 'Makkon',
            typeEn: 'Product Catalog',
            typePt: 'Catálogo de produtos',
            tecnology: 'woocommerce',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Catalog for a company that sells office chairs where the client can register and manage the products.',
            descriptionPt: 'Catálogo para empresa de vende cadeiras de escritório aonde o cliente pode cadastrar e gerenciar os produtos.',
            link: '',
        },
        {
            image: projectTrainmec,
            titleEn: 'Trainmec',
            titlePt: 'Trainmec',
            typeEn: 'Car accessories E-commerce',
            typePt: 'E-commerce de acessórios para carro',
            tecnology: 'woocommerce',
            developedReasonEn: 'Freelance',
            developedReasonPt: 'Freelance',
            descriptionEn: 'E-commerce for car accessories.',
            descriptionPt: 'E-commerce de acessorios para carros.',
            link: '',
        },
        {
            image: projectOrElleOurPages,
            titleEn: 'Or & Elle',
            titlePt: 'Or & Elle',
            typeEn: 'Institucional Pages',
            typePt: 'Páginas Institucionais',
            tecnology: 'shopify',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Development of the pages "Our Gold" and "Our Story".',
            descriptionPt: 'Desenvolvimento das páginas "Nosso Ouro" e "Nossa História"',
            link: '',
        },
        {
            image: projectMiniDreamersDesignersPage,
            titleEn: 'Mini Dreamers',
            titlePt: 'Mini Dreamers',
            typeEn: 'Search/Filter Page',
            typePt: 'Página de busca/filtro',
            tecnology: 'shopify',
            developedReasonEn: 'Developed at Work',
            developedReasonPt: 'Devenvolvido para Empresa',
            descriptionEn: 'Filter and search page to browse the clothing designers in the store, you can filter by letter or search by term.',
            descriptionPt: 'Pagina de filtro e busca para navegar entre os designers de roupas da loja, você pode filtrar por letra ou perquisar por termo.',
            link: '',
        }
    ];

    const [filter, setFilter] = useState("");
    var filterTecnology = "";

    if(query == ""){
        if(filter == "wordpress"){
            filterTecnology = projects.filter(x => x.tecnology ===  "wordpress" || x.tecnology ===  "woocommerce");
    
        }else if(filter == ""){
            filterTecnology = projects;
        }else{
            filterTecnology = projects.filter(x => x.tecnology ===  filter);
        }
    }else{
        filterTecnology = query;
    }

    const handleSearch = (e) =>{        
        var query = e.target.value.toLowerCase();
        filterTecnology = "";
        filterTecnology = projects.filter((x) =>
            x.titleEn.toLowerCase().indexOf(query) !== -1
        );
        setQuery(filterTecnology);
    }

    useEffect(() => {
        document.body.classList.add('hide-sidebar-mobile');
        console.log(projects.length+" projetos");
    },[]);
    

    return(
        <div>
            <Sidebar/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9 offset-md-3 col-index">
                        <Header/>
                        <div className="content-div p-30 col-md-12">
                            {language === 'en' ?                            
                            <PageTitle
                                title="Projects"
                                description="In this section you can see some projects I developed for the companies I worked and for study in my free time."
                                note="NOTE: For legal reasons, the link isn't available for all the projects developed as an employee in a company. Logos, texts and images have been replaced to protect the brand privacy in the available links"
                            />
                            :''}
                            {language === 'pt'?
                            <PageTitle
                                title="Projetos"
                                description="Veja nessa página alguns projetos que desenvolvi para as empresas que trabalhei e também para fins de estudo."
                                note="NOTA: Por motivos legais, os links não estão disponíveis para todos os projetos desenvolvidos para empresas. Logos, textos e imagens foram substituídos para proteger a privacidade da marca nos links disponíveis"
                            />
                            :''}
                            <div className="row">
                                <div className="col-md-12 mb-20">
                                    <div className="projects-header">
                                        <div className="search">
                                            {language === 'en' ? 
                                                <input type="text" className="input-search dark-mode-white dark-mode-border-pink"
                                                    placeholder="Search"
                                                    onChange={e => handleSearch(e)}
                                                    />
                                            :''}
                                            {language === 'pt' ? 
                                                <input type="text" className="input-search dark-mode-white dark-mode-border-pink"
                                                    placeholder="Buscar"
                                                    onChange={e => handleSearch(e)}
                                                    />
                                            :''}
                                            <i className="icon icon-search bg-dark-pink"></i>
                                        </div>
                                        <div className="filter">
                                            <div className="filter-title-width float-left cursor-pointer" onClick={() => setMenu(!openMenu)}>
                                                <i className="icon icon-filter bg-dark-pink align-middle mr-8"></i>
                                                {language === 'en' ?   
                                                    <span>Filter</span>
                                                :''}
                                                {language === 'pt' ?   
                                                    <span>Filtrar</span>
                                                :''}
                                            </div>
                                            <div className="filter-width float-left pr-20" onClick={() => setMenu(!openMenu)}>
                                                {filter == '' ?
                                                <span className="bg-dark-pink tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-coding bg-white align-middle mr-8"></i>
                                                    {language === 'en' ?   
                                                        <span>All</span>
                                                    :''}
                                                    {language === 'pt' ?   
                                                        <span>Tudo</span>
                                                    :''}
                                                </span>
                                                : ''}
                                                {filter == 'wordpress' ?
                                                <span className="bg-blue-wordpress tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-wordpress bg-white align-middle mr-8"></i>
                                                    Wordpress
                                                </span>
                                                : ''}
                                                {filter == 'woocommerce' ?
                                                <span className="bg-purple-woocommerce tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-woocommerce mr-8"></i>
                                                    WooCommerce
                                                </span>
                                                : ''}
                                                {filter == 'reactjs' ? 
                                                <span className="bg-blue-reactjs tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-reactjs bg-white align-middle mr-8"></i>
                                                    Reactjs
                                                </span>
                                                : ''}
                                                {filter == 'ionic' ?
                                                <span className="bg-blue-ionic tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-ionic bg-white align-middle mr-8"></i>
                                                    Ionic
                                                </span>
                                                : ''}
                                                {filter == 'html' ?
                                                <span className="bg-red-html tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-html bg-white mr-8"></i>
                                                    HTML/CSS/JS
                                                </span>
                                                : ''}
                                                {filter == 'angularjs' ?
                                                <span className="bg-red-angular tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-angular bg-white align-middle mr-8"></i>
                                                    AngularJS
                                                </span>
                                                : ''}
                                                {filter == 'vuejs' ?
                                                <span className="bg-green-vuejs tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-vuejs bg-white align-middle mr-8"></i>
                                                    VueJS
                                                </span>
                                                : ''}
                                                {filter == 'shopify' ?
                                                <span className="bg-green-shopify tag white align-middle mb-2 cursor-pointer d-block text-center">
                                                    <i className="icon icon-shopify bg-white align-middle mr-8"></i>
                                                    Shopify
                                                </span>
                                                : ''}
                                            </div>
                                            <div className="ml-20 float-left cursor-pointer" onClick={() => setMenu(!openMenu)}>
                                                <i className="icon icon-arrow-down bg-dark-pink"></i>
                                            </div>
                                            { openMenu == true ?
                                            <div className="filter-list filter-width">
                                                <span className="bg-dark-pink tag white align-middle mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter(""); setMenu(false)}}>
                                                    <i className="icon icon-coding bg-white align-middle mr-8"></i>
                                                    {language === 'en' ?   
                                                        <span>All</span>
                                                    :''}
                                                    {language === 'pt' ?   
                                                        <span>Tudo</span>
                                                    :''}
                                                </span>
                                                <span className="bg-blue-reactjs tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("reactjs"); setMenu(false)}}>
                                                    <i className="icon icon-reactjs bg-white mr-8"></i>
                                                    Reactjs
                                                </span>
                                                <span className="bg-red-angular tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("angularjs"); setMenu(false)}}>
                                                    <i className="icon icon-angular bg-white mr-8"></i>
                                                    AngularJS
                                                </span>
                                                <span className="bg-green-vuejs tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("vuejs"); setMenu(false)}}>
                                                    <i className="icon icon-vuejs bg-white mr-8"></i>
                                                    VueJS
                                                </span>
                                                <span className="bg-blue-ionic tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("ionic"); setMenu(false)}}>
                                                    <i className="icon icon-ionic bg-white mr-8"></i>
                                                    Ionic
                                                </span>
                                                <span className="bg-blue-wordpress tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("wordpress"); setMenu(false)}}>
                                                    <i className="icon icon-wordpress bg-white mr-8"></i>
                                                    Wordpress
                                                </span>
                                                <span className="bg-purple-woocommerce tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("woocommerce"); setMenu(false)}}>
                                                    <i className="icon icon-woocommerce mr-8"></i>
                                                    WooCommerce
                                                </span>
                                                <span className="bg-green-shopify tag white mb-2 cursor-pointer d-block text-center" onClick={() => {setFilter("shopify"); setMenu(false)}}>
                                                    <i className="icon icon-shopify bg-white mr-8"></i>
                                                    Shopify
                                                </span>
                                            </div>
                                            :''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {language === 'en' ? 
                                filterTecnology.map((project) => (
                                    <div className="col-md-4 mb-30">
                                        <CardProject
                                            image={project.image}
                                            title={project.titleEn}
                                            type={project.typeEn}
                                            tecnology={project.tecnology}
                                            developedReason={project.developedReasonEn}
                                            description={project.descriptionEn}
                                            link={project.link}
                                            className="h-full"
                                        />
                                    </div>
                                ))
                                :''}
                                {language === 'pt' ? 
                                filterTecnology.map((project) => (
                                    <div className="col-md-4 mb-30">
                                        <CardProject
                                            image={project.image}
                                            title={project.titlePt}
                                            type={project.typePt}
                                            tecnology={project.tecnology}
                                            developedReason={project.developedReasonPt}
                                            description={project.descriptionPt}
                                            link={project.link}
                                            className="h-full"
                                        />
                                    </div>
                                ))
                                :''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactMe />
        </div>
    )

}

export default Projects;