import React, {useContext} from "react";
import robot from "../img/robot.gif";
import GlobalContext from "../context/GlobalContext";
import { NavLink, Link } from "react-router-dom";

const ContactMe = () =>{
    const {language, setLanguage} = useContext(GlobalContext);

    return(
        <Link to="/contact">
            <div className="contact-me bg-pink cursor-pointer rounded-small">
                <img src={robot} />
                
                {language == 'en' ?
                    <p className="white font-comfortaa text-center mt-10">Contact me</p>
                :''}
                {language == 'pt' ?
                    <p className="white font-comfortaa text-center mt-10">Contato</p>
                :''}
            </div>
        </Link>
    )

}

export default ContactMe;